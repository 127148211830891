import { computed, Directive, HostBinding, input, signal, Signal } from '@angular/core';
import { Theme, ThemeService } from './theme.service';

@Directive({
  selector: '[appFormField]',
  standalone: true
})
export class FormFieldDirective {

  topBorderRadius = input<string>('0');
  bottomBorderRadius = input<string>('0');

  // input styling
  @HostBinding('style.--mdc-filled-text-field-container-shape') get borderRadius() {
    return this.topBorderRadius();
  }

  @HostBinding('style.--mdc-filled-text-field-input-text-color') get inputTextColor() {
    return this.formStyle().inputTextColor;
  }
  @HostBinding('style.--mdc-filled-text-field-input-text-placeholder-color') get inputPlaceholderColor() {
    return this.formStyle().inputPlaceholderColor;
  }

  @HostBinding('style.--mat-form-field-container-text-size') get inputTextSize() {
    return this.formStyle().inputTextSize;
  }

  @HostBinding('style.--mdc-filled-text-field-container-color') get textFieldBackgroundColor() {
    return this.formStyle().textFieldBackgroundColor;
  }

  @HostBinding('style.background-color') get totalBackgroundColor() {
    return this.formStyle().totalBackgroundColor;
  }
  @HostBinding('style.border-top-left-radius') get btlr() {
    return this.topBorderRadius();
  };

  @HostBinding('style.border-top-right-radius') get btrr() {
    return this.topBorderRadius();
  };

  @HostBinding('style.border-bottom-right-radius') get bbrr() {
    return this.bottomBorderRadius();
  };

  @HostBinding('style.border-bottom-left-radius') get bblr() {
    return this.bottomBorderRadius();
  };

  @HostBinding('style.--mat-form-field-container-height') get height() {
    return this.formStyle().height;
  }

  //label styling
  @HostBinding('style.--mdc-filled-text-field-error-label-text-color') get labelAfterErrorColor() {
    return this.formStyle().labelAfterErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-error-hover-label-text-color') get labelHoverAfterErrorColor() {
    return this.formStyle().labelHoverAfterErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-error-focus-label-text-color') get labelFocusAfterErrorColor() {
    return this.formStyle().labelFocusAfterErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-label-text-color') get labelBeforeErrorColor() {
    return this.formStyle().labelBeforeErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-hover-label-text-color') get labelHoverBeforeErrorColor() {
    return this.formStyle().labelHoverBeforeErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-focus-label-text-color') get labelFocusBeforeErrorColor() {
    return this.formStyle().labelFocusBeforeErrorColor;
  }

  @HostBinding('style.--mdc-filled-text-field-label-text-size') get labelTextSize() {
    return this.formStyle().labelTextSize;
  }

  @HostBinding('style.--mdc-filled-text-field-label-text-weight') get labelTextWeight() {
    return this.formStyle().labelTextWeight;
  }

  @HostBinding('style.--mdc-filled-text-field-label-text-tracking') labelTextLetterSpace = 'inherit';

  //hint styling
  @HostBinding('style.--mat-form-field-error-text-color') get hintErrorTextColor() {
    return this.formStyle().hintErrorTextColor;
  }

  @HostBinding('style.--mat-form-field-text-color') get hintTextColor() {
    return this.formStyle().hintTextColor;
  }

  // @HostBinding('style.--mdc-text-field-label-ink-color') placeholder= 'green';
  @HostBinding('style.--mat-form-field-subscript-text-line-height') hintLineHeight = '1rem';
  @HostBinding('style.--mat-form-field-subscript-text-size') hintFontSize = '0.7rem';
  @HostBinding('style.margin-top') marginTop = '9px';

  formStyle: Signal<FormStyle> = signal<FormStyle>(new FormStyle())
  theme: Signal<Theme> = signal(new Theme());

  constructor(
    private themeService: ThemeService,
  ) {
    this.theme = computed(() => this.themeService.getTheme()());
    this.buildTheme();
  }

  buildTheme() {
    this.formStyle = computed(() => {
      const formStyle = new FormStyle()
      formStyle.textFieldBackgroundColor = (this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('5-75')?.tertiary.toHexString() : this.theme().getPalette('default').getShade('5-85')?.tertiary.toHexString()) ?? 'white';
      formStyle.inputTextColor = this.theme().getPalette('default').getShade('5-15')?.tertiary.toHexString() ?? 'black';
      formStyle.labelBeforeErrorColor = (this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('90-30')?.primary.toHexString() : this.theme().getPalette('default').getShade('80-35')?.primary.toHexString()) ?? 'white';
      formStyle.labelAfterErrorColor = this.theme().getPalette('default').getShade('95-30')?.secondary.toHexString() ?? 'black';
      formStyle.labelFocusAfterErrorColor = formStyle.labelAfterErrorColor;
      formStyle.labelHoverAfterErrorColor = formStyle.labelAfterErrorColor;
      formStyle.labelHoverBeforeErrorColor = this.theme().getPalette('default').getShade('95-55')?.primary.toHexString() ?? 'black';
      formStyle.labelFocusBeforeErrorColor = formStyle.labelHoverBeforeErrorColor;
      formStyle.hintTextColor = 'red';
      formStyle.hintErrorTextColor = this.theme().getPalette('default').getShade('85-20')?.secondary.toHexString() ?? 'black';
      formStyle.labelTextSize = '15px';
      formStyle.height = '50px';
      formStyle.labelTextWeight = '500';
      formStyle.inputPlaceholderColor = this.theme().getPalette('default').getShade('5-20')?.tertiary.toHexString() ?? 'black';
      formStyle.totalBackgroundColor = this.theme().getPalette('default').getShade('15-80')?.tertiary.toHexString() ?? 'black';
      return formStyle;
    })
  }
}

class FormStyle {
  inputTextColor = 'black';
  inputTextSize = '15px';
  inputPlaceholderColor = '#777';
  textFieldBackgroundColor = 'white';
  height = '56px';
  labelAfterErrorColor = 'red';
  labelHoverAfterErrorColor = 'red';
  labelFocusAfterErrorColor = 'red';
  labelBeforeErrorColor = 'red';
  labelFocusBeforeErrorColor = 'red';
  labelHoverBeforeErrorColor = 'red';
  labelTextSize = 'inherit';
  labelTextWeight = 'inherit';
  labelTextLetterSpace = 'inherit';
  hintErrorTextColor = 'red';
  hintTextColor = 'black';
  totalBackgroundColor = 'black';
  hintFontSize = '0.7rem';
}
