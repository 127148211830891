import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { ProjectsComponent } from 'app/projects/projects.component';
import { HomeComponent } from 'app/home/home.component';
import { ContactComponent } from 'app/contact/contact.component';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  routes: Route[] = [];

  constructor(private router: Router) { }

  registerAll(): Route[] {
    this.buildRoutes();
    this.routes.forEach((route: Route) => this.router.config.push(route));
    return this.routes;
  }

  private buildRoutes() {
    this.routes = [
      { path: 'home', component: HomeComponent, pathMatch: 'full' } as Route,
      { path: 'projects', component: ProjectsComponent, pathMatch: 'full' } as Route,
      { path: 'contact', component: ContactComponent, pathMatch: 'full' } as Route,
      { path: '**', redirectTo: 'home', pathMatch: 'full' } as Route,
    ];
  }
}
