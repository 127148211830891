import {
  Component, computed, input, InputSignal, OnDestroy,
  OnInit, signal, Signal, WritableSignal
} from '@angular/core';
import {
  NavigationStart, Router, RouterLink,
  RouterLinkActive, RouterOutlet
} from '@angular/router';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CdkOption } from '@angular/cdk/listbox';
import { DeviceDetectorService } from 'app/interactor/device-detector/device-detector.service';
import { Theme, ThemeService } from 'app/theme/theme.service';
import { NavigationService } from "app/interactor/navigation/navigation.service";

export class SidenavStylingConfig {
  lowWidthPx = 45;
  highWidthPx = 250;
  transitionMS = 300;
  vertical: { top: string; height: string; } = { top: '50px', height: '0' };
  borderRadiusPx: { top: number; bottom: number; } = {top: 5, bottom: 0};
  initialMode: 'expanded' | 'collapsed' | 'auto' = 'auto';
}

export class SidenavRoutingConfig {
  elements: SidenavRoutingElement[] = []
}

export class SidenavRoutingElement {
  private static counter = 0;
  id: string;
  matIconName = 'home';
  content = 'Home';
  path = '/home';
  isActive = false;
  children: SidenavRoutingElement[] = [];

  constructor() {
    this.id = `${SidenavRoutingElement.counter++}`;
  }
}

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [MatSlideToggleModule, MatSidenavContainer, MatButton, MatSidenav, MatSidenavContent, MatIconModule, CdkOption, MatIconButton, NgClass, NgStyle, NgIf, RouterLink, RouterOutlet, RouterLinkActive],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss'
})
export class NavigationComponent implements OnInit, OnDestroy {

  sidenavStylingConfig: InputSignal<SidenavStylingConfig> = input<SidenavStylingConfig>(new SidenavStylingConfig());
  sidenavRoutingConfig: InputSignal<SidenavRoutingConfig> = input<SidenavRoutingConfig>(new SidenavRoutingConfig());
  routingConfig: WritableSignal<SidenavRoutingConfig> = signal(new SidenavRoutingConfig());
  private readonly isMobile: Signal<boolean | null> | (() => boolean) = () => false;
  style: WritableSignal<Record<string, string | number>> = signal({});
  isCollapsed = signal(true);
  private readonly minWidth = 45;
  private readonly maxTransitionMS = 1500;
  private subscriptions: Subscription[] = [];
  theme: Signal<Theme> = signal(new Theme());

  constructor(
    private deviceDetector: DeviceDetectorService,
    private themeService: ThemeService,
    private router: Router,
    private navigationService: NavigationService) {
    this.theme = computed(() => this.themeService.getTheme()())
    this.isMobile = computed(() => this.deviceDetector.isMobile() || this.deviceDetector.isSmallScreen()());
  }

  static numberToPixel(value: number): string {
    return `${value}px`;
  }

  static numberToMillisecond(value: number): string {
    return `${value}ms`;
  }

  ngOnInit() {
    this.routingConfig.update(() =>  this.sidenavRoutingConfig())
    this.setIsCollapsed();
    this.setLocation();
    this.setWidth();
    this.setTransition();
    this.setBorderRadius();
    this.setInitialActiveRoute();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private setInitialActiveRoute() {
    this.subscriptions.push(
      this.router.events.subscribe(e => {
        if(e instanceof NavigationStart) {
          this.routingConfig.update(c => {
            c.elements.forEach(el => el.isActive = el.path === e.url || (el.path === '/home' && e.url === '/'));
            return c;
          })
        }
      })
    );
  }

  toggle() {
    this.isCollapsed.update(value => !value);
    this.setWidth();
  }

  updateActivatedRoute(navElement: SidenavRoutingElement) {
    this.routingConfig.update(c => {
      c.elements.forEach(el => el.isActive = el.id === navElement.id);
      return c;
    });
  }

  private setWidth(switchCondition: boolean = this.isCollapsed()) {
    this.style.update(value => {
      value['width'] = switchCondition ?
        NavigationComponent.numberToPixel(Math.max(this.sidenavStylingConfig().lowWidthPx, this.minWidth)) :
        NavigationComponent.numberToPixel(this.sidenavStylingConfig().highWidthPx);
      return value;
    });
  }

  private setTransition(key = 'width') {
    this.style.update(value => {
      value['transition'] = `${key} ${NavigationComponent.numberToMillisecond(Math.min(this.sidenavStylingConfig().transitionMS, this.maxTransitionMS))}`;
      return value;
    });
  }

  private setLocation() {
    this.style.update(value => {
      value['height'] = this.sidenavStylingConfig().vertical.height;
      value['top'] = this.sidenavStylingConfig().vertical.top;
      return value;
    })
  }

  private setBorderRadius() {
    this.style.update(value => {
      value['border-top-right-radius'] = NavigationComponent.numberToPixel(this.sidenavStylingConfig().borderRadiusPx.top);
      value['border-bottom-right-radius'] = NavigationComponent.numberToPixel(this.sidenavStylingConfig().borderRadiusPx.bottom);
      return value;
    })
  }

  private setIsCollapsed() {
    if (this.sidenavStylingConfig().initialMode === 'auto') {
      this.isCollapsed.update(() => !!this.isMobile());
      return;
    }
    this.isCollapsed.update(() => this.sidenavStylingConfig().initialMode === 'collapsed');
  }
}
