import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { ContactForm } from 'app/service/data-models/contact-form';
import { environment } from 'app/../environments/environment';
import { CsrfInteractor } from 'app/service/csrf-interactor/csrf-interactor.service';

@Injectable({
  providedIn: 'root',
})
export class PortfolioGoService {

  constructor(private httpClient: HttpClient, private csrfInteractor: CsrfInteractor) {}

  sendContactMessage(form: ContactForm) {
    const [headersRecord, err] = this.csrfInteractor.buildCSRFHeaderRecord();
    if (err != null) {
      console.error(err);
    }
    const headers = new HttpHeaders().set('X-XSRF-TOKEN-BODY', headersRecord['X-XSRF-TOKEN-BODY'])
      .set('X-XSRF-TOKEN-COOKIE', headersRecord['X-XSRF-TOKEN-COOKIE'])
    return this.httpClient.post<never>(`${environment.portFolioGoBaseUrl}/api/contact`, {form}, {headers});
  }
}
