<div class="page" appBgColor *ngIf="isSmallScreen() !== null">
  <div class="projects">
    @for (project of projects(); track project.generatedId) {
      <div class="project" [ngClass]="isSmallScreen() ? 'project-mobile' : 'project-desktop'">
        <mat-card appTextTheme class="content" [ngClass]="isSmallScreen() ? 'content-mobile' : 'content-desktop'">
          <div class="content-title">{{ project.title }}</div>
          <div class="content-describe">{{ project.description }}</div>
          <div class="demo">
            <img [ngSrc]="project.imageSrc"
                 [alt]="project.imageAlt"
                 [height]="project.imageSize.height"
                 [width]="project.imageSize.width"
                 (click)="onDemoClick(project.link)"
                 (keyup.enter)="onDemoClick(project.link)"
                 priority
                 class="project-image"
                 [tabindex]="0"
            >
          </div>
        </mat-card>
        <mat-card appTextTheme class="project-info" [ngClass]="isSmallScreen() ? 'project-info-mobile' : 'project-info-desktop'">
          <div class="content-block">
            <div class="block-title">{{ project.technologiesTitle }}</div>
            <div class="horizontal-list">
              @for (technology of project.technologies; track technology) {
                <div class="horizontal-item">
                  <app-puzzle-piece-diag
                    [color]="matIconColor()"
                    class="tech-icon">
                  </app-puzzle-piece-diag><div class="horizontal-text text-left">{{ technology }}</div>
                </div>
              }
            </div>
          </div>
          <div class="content-block">
            <div class="block-title">{{ project.developersTitle }}</div>
            <div class="vertical-list">
              @for (developer of project.developers; track developer) {
                <div class="vertical-item text-left">
                  <mat-icon class="dev-icon" fontIcon="engineering" [ngStyle]="{color: matIconColor()}"></mat-icon>
                  <div>{{ developer }}</div>
                </div>
              }
            </div>
          </div>
        </mat-card>
      </div>
    }
    @for (project of technicalProjects(); track project.generatedId) {
      <div class="project" [ngClass]="isSmallScreen() ? 'project-mobile' : 'project-desktop'">
        <mat-card appTextTheme class="content" [ngClass]="isSmallScreen() ? 'content-mobile' : 'content-desktop'">
          <div class="content-title">{{ project.title }}</div>
          <div class="content-describe">{{ project.description }}</div>
          <div class="highlight-title">{{ project.highlightsTitle }}</div>
          <div class="vertical-list margin-h-auto width-80p">
            @for (highlight of project.highLights; track highlight) {
              <div class="vertical-item margin-b7">
                <mat-icon
                  fontIcon="star"
                  [ngStyle]="{color: matIconColor()}"
                  class="highlight-icon">
                </mat-icon><div class="text-left"><app-html-builder [htmlTextBox]="highlight"></app-html-builder></div>
              </div>
            }
          </div>
        </mat-card>
        <mat-card appTextTheme class="project-info" [ngClass]="isSmallScreen() ? 'project-info-mobile' : 'project-info-desktop'">
          <div class="content-block">
            <div class="block-title">{{ project.technologiesTitle }}</div>
            <div class="horizontal-list">
              @for (technology of project.technologies; track technology) {
                <div class="horizontal-item">
                  <app-puzzle-piece-diag
                    [color]="matIconColor()"
                    class="tech-icon">
                  </app-puzzle-piece-diag><div class="horizontal-text text-left">{{ technology }}</div>
                </div>
              }
            </div>
          </div>
          <div class="content-block">
            <div class="block-title">{{ project.dataStructuresTitle }}</div>
            <div class="horizontal-list">
              @for (dataStructure of project.dataStructures; track dataStructure) {
                <div class="horizontal-item">
                  <mat-icon
                    fontIcon="account_tree"
                    [ngStyle]="{color: matIconColor()}"
                    class="ds-icon">
                  </mat-icon><div class="horizontal-text text-left">{{ dataStructure }}</div>
                </div>
              }
            </div>
          </div>
          <div class="content-block">
            <div class="block-title">{{ project.developersTitle }}</div>
            <div class="vertical-list">
              @for (developer of project.developers; track developer) {
                <div class="vertical-item text-left">
                  <mat-icon class="dev-icon" fontIcon="engineering" [ngStyle]="{color: matIconColor()}"></mat-icon>
                  <div>{{ developer }}</div>
                </div>
              }
            </div>
          </div>
        </mat-card>
      </div>
    }
  </div>
</div>
