
@for (themeButton of themeButtons(); track themeButton.themeId) {
  <button class="icon-button"
          role="button"
          [attr.aria-label]="themeButton.themeId + 'theme'"
          (click)="onThemeIconClick(themeButton.themeId)">
    <div [ngClass]="selected() === 'dark' ? 'light-icon' : 'dark-icon'">
      <div [ngClass]="themeButton.isSelected ? 'active' : 'de-active'">
        <mat-icon [fontIcon]="themeButton.iconId" class="size"></mat-icon>
      </div>
    </div>
  </button>
}
