import { Injectable } from '@angular/core';
import { CookieService } from "app/service/cookie/cookie.service";

@Injectable({
  providedIn: 'root',
})
export class CsrfInteractor {
  tokenInjected: string | null = null;

  constructor(private cookieService: CookieService) {}

  buildCSRFHeaderRecord(): [Record<string, string>, Error | null] {
    const headersObj: Record<string, string> = {};
    const csrfTokenBody = this.getCsrfTokenFromBody();

    if (csrfTokenBody) {
      headersObj['X-XSRF-TOKEN-BODY'] = csrfTokenBody;
    } else {
      return [headersObj, new Error('CSRF body token not found')];
    }
    const [csrfTokenCookie, err] = this.cookieService.getCookieFromDocument('XSRF-TOKEN');
    headersObj['X-XSRF-TOKEN-COOKIE'] = csrfTokenCookie;
    if (err != null) {
      return [headersObj, err];
    }
    return [headersObj, null];
  }

  getCsrfTokenFromBody(): string | null {
    if (this.tokenInjected != null) {
      return this.tokenInjected;
    }
    const meta = document.querySelector('meta[name="csrf-token"]');
    this.tokenInjected = meta ? meta.getAttribute('content') : null;
    return this.tokenInjected;
  }
}
