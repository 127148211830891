<div class="parent" [ngStyle]="style()" [ngClass]="theme().getId()">
    <button class="menu-button" role="button" aria-label="menu-button" (click)="toggle()">
      <mat-icon fontIcon=menu></mat-icon>
    </button>

    @for (navElement of routingConfig().elements; track navElement.id) {
      <div style="margin: 3px 0;" class="">
        <div [routerLink]="navElement.path"
             class="nav-button"
             [ngClass]="navElement.isActive ? 'active' : 'de-active'"
             tabindex="-1"
             >
          <button class="flex-row" (click)="updateActivatedRoute(navElement)">

            <div class="flex-inner-column-left icon-level-1">
              <mat-icon fontIcon="{{ navElement.matIconName }}"></mat-icon>
            </div>
            <div *ngIf="!isCollapsed()" class="flex-inner-column-right button-text fade-in-element">{{ navElement.content }}</div>
          </button>
        </div>
        @for (childElement of navElement.children; track childElement.id) {
          <div class="level-2">
            <button class="flex-row level-2-button"
                    (click)="updateActivatedRoute(childElement)"
                    role="menuitem" [attr.aria-label]="childElement.content">
              <div class="flex-inner-column-right-level-2 button-text-level-2">{{ childElement.content }}</div>
            </button>
          </div>
        }
      </div>
    }
  </div>


