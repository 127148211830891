import { Component, computed, input, InputSignal, signal, Signal } from '@angular/core';
import { NgStyle } from '@angular/common';
import { Theme, ThemeService } from 'app/theme/theme.service';

@Component({
  selector: 'app-linkedin',
  standalone: true,
  imports: [
    NgStyle
  ],
  templateUrl: './linkedin.component.html',
  styleUrl: './linkedin.component.scss'
})
export class LinkedinComponent {
  theme: Signal<Theme> = signal(new Theme());
  border: Signal<string> = signal('2px blue solid');
  size: InputSignal<string> = input<string>('34px');

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.getTheme();
    this.border = computed(() => {
      const borderColor = this.theme().getId() === 'dark' ? '#FFF' : 'blue'
      return `2px ${borderColor} solid`
    })
  }
}
