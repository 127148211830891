import { Component, computed, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatCard, MatCardContent, MatCardTitle } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { Theme, ThemeService } from 'app/theme/theme.service';
import { TextThemeDirective } from 'app/theme/text-theme.directive';
import { PuzzlePieceDiagComponent } from 'app/svg/puzzle-piece-diag/puzzle-piece-diag.component';
import { DeviceDetectorService } from 'app/interactor/device-detector/device-detector.service';
import { SampleOneComponent } from 'app/background/sample-one/sample-one.component';
import { BackgroundColorDirective } from 'app/theme/background-color.directive';
import { HtmlBuilderComponent, HtmlTextBox } from "app/html-builder/html-builder.component";

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [
    NgStyle,
    MatCard,
    TextThemeDirective,
    PuzzlePieceDiagComponent,
    MatIcon,
    NgOptimizedImage,
    NgClass,
    SampleOneComponent,
    BackgroundColorDirective,
    MatCardTitle,
    MatCardContent,
    HtmlBuilderComponent,
    NgIf
  ],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss'
})
export class ProjectsComponent implements OnInit {
  theme: Signal<Theme>  = signal(new Theme());
  projects: WritableSignal<ProjectInfo[]> = signal([]);
  technicalProjects: WritableSignal<TechnicalProject[]> = signal([]);
  isSmallScreen: Signal<boolean | null> = signal(false);
  matIconColor: Signal<string> = signal('black');

  constructor(private themeService: ThemeService, private deviceDetector: DeviceDetectorService) {
    this.theme = this.themeService.getTheme();
    this.matIconColor = computed(() => this.theme().getPalette('default').getShade(this.theme().getId() === 'dark' ? '50-65' : '70-45')?.primary?.toHexString() ?? this.matIconColor());
    this.isSmallScreen = this.deviceDetector.isSmallScreen(700);
  }

  ngOnInit() {
    this.projects.set(this.buildProjects());
    this.technicalProjects.set(this.buildTechnicalProjects());
  }

  onDemoClick(url: string) {
    window?.open(url)
  }

  buildProjects(): ProjectInfo[] {
    const taxExplained = new ProjectInfo()
      .setTitle('Tax Explained')
      .setDescription('Calculates Canadian income tax and suggests optimal RRSP contributions.')
      .setTechnologies(['Go', 'Postgres', 'Azure Vault', 'Docker Swarm', 'React', 'Nginx', 'GRPC', 'RestAPI'])
      .setDevelopers(['Mahsa Aghajani', 'Hesam Eskandari (myself)'])
      .setImageSrc('/images/project-tax.png')
      .setImageSize(320, 200)
      .setImageAlt('tax-explained-homepage-image')
      .setLink('https://tax-explained.com');
    const portfolio = new ProjectInfo()
      .setTitle('Portfolio')
      .setDescription('Brief introduction to my resume and personal projects')
      .setTechnologies(['Angular', 'Go', 'Linode', 'Docker Swarm', 'Nginx', 'RestAPI', 'Amazon SES', 'MailerSend'])
      .setDevelopers(['Hesam Eskandari'])
      .setImageSrc('/images/photo.png')
      .setImageAlt('personal-website-image')
      .setLink('https://hesameskandari.com');
    return [taxExplained, portfolio];
  }

  buildTechnicalProjects(): TechnicalProject[] {
    const loggingService = new TechnicalProject()
      .setTitle('Logging Service')
      .setDescription('High performance central log persistent service.')
      .setHighlights([
        new HtmlTextBox().setText('A service that receives and persists over one million logs per second'),
        new HtmlTextBox().setText('A Go client SDK which sends logs over the network').setHyperlink('https://github.com/gerdooshell/tax-logger-client-go'),
        new HtmlTextBox().setText('A GRPC server for low latency, high performance communication'),
        ])
      .setTechnologies(['Go', 'GRPC', 'Postgres', 'Azure Vault', 'GORM'])
      .setDataStructures(['Queue', 'LRU Cache', 'Channel'])
      .setDevelopers(['Hesam Eskandari'])

    return [loggingService]
  }
}

class ProjectInfo {
  generatedId: string;
  title = 'Project Title';
  description = 'Project Description';
  technologiesTitle = 'Technologies';
  technologies: string[] = ['technology 1', 'technology 2'];
  developersTitle = 'Developers';
  developers: string[] = ['developers 1', 'developers 2'];
  imageSrc = '';
  imageAlt = '';
  imageSize: {width: number, height: number} = {width: 200, height: 200};
  link = '';

  constructor() {
    this.generatedId = `${Date.now()}-${Math.random()}`
  }

  setTitle(title: string): this {
    this.title = title;
    return this;
  }

  setDescription(description: string): this {
    this.description = description;
    return this;
  }

  setTechnologies(technologies: string[]): this {
    this.technologies = technologies;
    return this;
  }

  setDevelopers(developers: string[]): this {
    this.developers = developers;
    return this;
  }

  setImageSrc(imageSrc: string): this {
    this.imageSrc = imageSrc;
    return this;
  }

  setImageAlt(imageAlt: string): this {
    this.imageAlt = imageAlt;
    return this;
  }

  setLink(link: string): this {
    this.link = link;
    return this;
  }

  setImageSize(width: number, height: number): this {
    this.imageSize = {width: width, height: height};
    return this;
  }
}

class TechnicalProject {
  generatedId: string;
  title = 'Project Title';
  description = 'Project Description';
  technologiesTitle = 'Technologies';
  technologies: string[] = [];
  developersTitle = 'Developers';
  developers: string[] = [];
  dataStructuresTitle = 'Data Structures';
  dataStructures: string[] = [];
  highlightsTitle = 'Highlights';
  highLights: HtmlTextBox[] = [];


  constructor() {
    this.generatedId = `${Date.now()}-${Math.random()}`
  }

  setTitle(title: string): this {
    this.title = title;
    return this;
  }

  setDescription(description: string): this {
    this.description = description;
    return this;
  }

  setTechnologies(technologies: string[]): this {
    this.technologies = technologies;
    return this;
  }

  setTechnologiesTitle(title: string): this {
    this.technologiesTitle = title;
    return this;
  }

  setDevelopers(developers: string[]): this {
    this.developers = developers;
    return this;
  }

  setDataStructuresTitle(title: string): this {
    this.dataStructuresTitle = title;
    return this;
  }

  setDataStructures(dataStructures: string[]): this {
    this.dataStructures = dataStructures;
    return this;
  }

  setHighlightsTitle(title: string): this {
    this.highlightsTitle = title;
    return this;
  }

  setHighlights(highlights: HtmlTextBox[]): this {
    this.highLights = highlights;
    return this;
  }
}
