<div class="page" appBgColor *ngIf="isSmallScreen() !== null">
  <div class="card-group" [ngClass]="isSmallScreen() ? 'card-group-mobile' : 'card-group-desktop'">
    <mat-card appTextTheme class="card-one" [ngClass]="isSmallScreen() ? 'card-one-mobile' : 'card-one-desktop'">
      <div class="photo-box">
        <img priority
             [hidden]="!isImageLoaded()"
             (load)="onImageLoad()"
             ngSrc="/images/photo.png"
             alt="hesam-eskandari-photo"
             class="photo"
             width="200"
             height="200">
        @if (isImageLoaded()) {
          <mat-icon fontIcon="person" class="photo icon"></mat-icon>
        }
      </div>
      <div class="name">
        Hesam Eskandari
      </div>
      <div class="prof">
        Software Engineer
      </div>
      <div class="prof">
        Vancouver, Canada
      </div>
      <div class="brief-history">
        <div class="history-item">
          <div class="activity">
            <div class="what">Software developer</div>
            <div class="when">2021 - Today</div>
          </div>
          <div class="details">
            <div class="explain">Localintel Inc</div>
            <div class="where">Vancouver, Canada</div>
          </div>
          <div class="horizontal-line">
            <hr>
          </div>
        </div>
        <div class="history-item">
          <div class="activity">
            <div class="what">Software developer</div>
            <div class="when">2020 - 2021</div>
          </div>
          <div class="details">
            <div class="explain">Vendasta</div>
            <div class="where">Saskatoon, Canada</div>
          </div>
          <div class="horizontal-line">
            <hr>
          </div>
        </div>
        <div class="history-item">
          <div class="activity">
            <div class="what">Master's study</div>
            <div class="when">2017 - 2019</div>
          </div>
          <div class="details">
            <div class="explain">Information Technology</div>
            <div class="where">ÉTS, Montreal, Canada</div>
          </div>
        </div>
      </div>
      <div class="social">
        <div class="social-item">
          <button class="social-button"
                  role="button"
                  aria-label="github-button"
                  (click)="onGithubClicked()">
            <app-github class="social-icon"></app-github>
          </button>
          <div class="social-text">Github</div>
        </div>
        <div class="social-item">
          <button class="social-button"
                  aria-label="linkedin-button"
                  (click)="onLinkedInClicked()"
                  role="button">
            <app-linkedin class="social-icon"></app-linkedin>
          </button>
          <div class="social-text">LinkedIn</div>
        </div>
      </div>
    </mat-card>
    <mat-card appTextTheme class="card-two" [ngClass]="isSmallScreen() ? 'card-two-mobile' : 'card-two-desktop'">
      <div class="content-box">
        <div class="resume-heading">Main Stacks</div>
        <div class="stacks">
          @for (stackItem of ['Golang', 'Angular', 'Python', 'Docker', 'Bash', 'SQL']; track stackItem) {
            <div class="stack-item">
              <mat-icon fontIcon="verified"
                        class="stack-icon"
                        [ngStyle]="{'color': matIconColor()}">

              </mat-icon>
              <div class="stack-text">{{ stackItem }}</div>
            </div>
          }
        </div>
      </div>
      <div class="content-box">
        <div class="resume-heading">Highlights</div>
        <div class="highlights">
          @for (content of highlights(); track content) {
            <div class="itemized-element">
              <mat-icon fontIcon="star" class="highlight-icon" [ngStyle]="{'color': matIconColor()}"></mat-icon>
              <div class="highlight-text">{{ content }}</div>
            </div>
          }
        </div>
      </div>
      <div class="content-box">
        <div class="resume-heading">Technical Skills</div>
        <div class="tech-skills">
          @for (skill of ['PostgreSQL', 'PostGIS', 'GRPC', 'REST API', 'OpenCV', 'GoCV', 'Numpy', 'TypeScript', 'Flask', 'Selenium']; track skill) {
            <div class="skill-item">
              <app-puzzle-piece-diag [color]="matIconColor()" class="skill-icon"></app-puzzle-piece-diag><div class="skill-text">{{ skill }}</div>
            </div>
          }
        </div>
      </div>
    </mat-card>
  </div>
</div>

