import {
  computed, Directive, HostBinding, HostListener,
  input, signal, Signal, WritableSignal
} from '@angular/core';
import {Theme, ThemeService} from "./theme.service";

@Directive({
  selector: '[appSubmitButton]',
  standalone: true
})
export class SubmitButtonDirective {
  isDisabled = input<boolean>(false);

  backgroundColorValue: Signal<string> = signal('white');
  backgroundColorMouseEnterValue: WritableSignal<string | null> = signal(null);
  backgroundColorKeyDownValue: WritableSignal<string | null> = signal(null);
  @HostBinding('style.backgroundColor') get backgroundColor() {
    return this.backgroundColorValue();
  }

  @HostBinding('style.color') get color() {
    const disabledColor = this.isDisabled() ? this.theme().getPalette('default').getShade('35-90')?.tertiary.toHexString() : null;
    const regularColor = this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('20-15')?.tertiary.toHexString() : this.theme().getPalette('default').getShade('20-90')?.primary.toHexString();
    return disabledColor ?? regularColor;
  }

  @HostListener('focus', ['$event'])
  @HostListener('mouseenter', ['$event.target.style']) onMouseEnter() {
    this.backgroundColorMouseEnterValue.set(this.theme().getPalette('default').getShade('85-65')?.primary.toHexString() ?? 'black');
  }

  @HostListener('blur', ['$event'])
  @HostListener('mouseleave', ['$event.target.style']) onMouseLeave() {
    this.backgroundColorMouseEnterValue.set(null);
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('keydown.enter', ['$event']) onKeyDown(event: MouseEvent | KeyboardEvent) {
    if (typeof event === typeof MouseEvent && (event as MouseEvent)?.button === 2) {
      return;
    }
    const color = this.theme().getPalette('default').getShade('90-60')?.primary.toHexString();
    this.backgroundColorKeyDownValue.set(color ?? null);
  }
  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:keyup.enter', ['$event']) onKeyUp(event: MouseEvent | KeyboardEvent) {
    if (typeof event === typeof MouseEvent && (event as MouseEvent)?.button === 2) {
      return;
    }
    this.backgroundColorKeyDownValue.set(null);
  }

  theme: Signal<Theme> = signal(new Theme('dark'));

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.getTheme();
    this.backgroundColorValue = computed(() => {
      const disabledColor = this.isDisabled() ? this.theme().getPalette('default').getShade('5-40')?.primary.toHexString() : null;
      const regularColor = this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('65-70')?.primary.toHexString() : this.theme().getPalette('default').getShade('60-50')?.primary.toHexString();
      return disabledColor ??
        this.backgroundColorKeyDownValue() ??
        this.backgroundColorMouseEnterValue() ??
        regularColor ??
        'black';
    });
  }
}
