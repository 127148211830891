<div class="svg">
  <svg width="17px"
       height="17px"
       viewBox="0 0 24 24"
       [attr.fill]="color()"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L15.6 5.6C18 -0.7 24.7 6 18.4 8.4L22 12L18.4 15.6C16 9.3 9.3 16 15.6 18.4L12 22L8.4 18.4C6 24.7 -0.7 18 5.6 15.6L2 12L5.6 8.4C8 14.7 14.7 8 8.4 5.6L12 2Z"
          [attr.stroke]="theme().getId() === 'dark' ? 'white' : 'black'"
          stroke-width="0"
          stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</div>
