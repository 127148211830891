import {
  computed,
  Directive,
  HostBinding,
  HostListener,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  signal,
  Signal
} from '@angular/core';
import { Subscription } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { TextTheme, Theme, ThemeService } from './theme.service';
import { Color } from "./color";

@Directive({
  selector: '[appTextTheme]',
  standalone: true
})
export class TextThemeDirective implements OnInit,  OnDestroy {

  private readonly theme: Signal<Theme> = signal(new Theme('dark'));
  private readonly subscriptions: Subscription[] = [];
  hoverColor: InputSignal<string> = input<string>('');
  defaultBoxShadow = '';

  @HostBinding('style.color') fontColor = '';
  @HostBinding('style.backgroundColor') backgroundColor = '';
  @HostBinding('style.boxShadow') boxShadow = '';

  constructor(private themeService: ThemeService) {
    this.theme = computed(() => this.themeService.getTheme()());
    this.backgroundColor = this.theme().getTextTheme('default-card').fontBackgroundColor.toHexString();
    this.subscriptions.push(
      toObservable(this.theme).subscribe(
        () => {
          this.backgroundColor = this.theme().getTextTheme('default-card').fontBackgroundColor.toHexString();
          this.fontColor = this.theme().getTextTheme('default-card').fontColor.toHexString();
          this.defaultBoxShadow = this.theme().getId() === 'dark' ? '0 0 2px rgba(255, 255, 255, 0.3)' : '0 0 2px rgba(0, 0, 0, 0.2)';
          this.updateBoxShadow();
        },
      )
    );
  }

  private registerThemeConfigs() {
    const lightColor = this.theme().getPalette('default').getShade('15-95')?.tertiary ?? Color.fromHex('#DEF');
    const darkColor = this.theme().getPalette('default').getShade('20-10')?.tertiary ?? Color.fromHex('#2A2A33');
    this.themeService.registerTextTheme('dark', 'default-card', new TextTheme().setFontColor(lightColor).setFontBackgroundColor(darkColor))
    this.themeService.registerTextTheme('light', 'default-card', new TextTheme().setFontColor(darkColor).setFontBackgroundColor(lightColor))
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    this.setHoverColor(true);
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.setHoverColor(false);
  }

  ngOnInit() {
    this.registerThemeConfigs();
    this.updateBoxShadow();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setHoverColor(isHovering: boolean): void {
    if (!this.hoverColor()?.length) {
      return;
    }
    this.boxShadow = isHovering ? `0 0 4px ${this.hoverColor()}` : this.defaultBoxShadow;
  }

  updateBoxShadow() {
    this.boxShadow = this.defaultBoxShadow;
  }
}
