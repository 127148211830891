import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  NavigationComponent,
  SidenavRoutingConfig,
  SidenavRoutingElement,
  SidenavStylingConfig
} from "./navigation/navigation.component";
import { RoutingService } from "./interactor/routing/routing.service";
import { HeaderComponent } from "./header/header.component";


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NavigationComponent, HeaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'portfolio';
  sidenavRoutingConfig: SidenavRoutingConfig = new SidenavRoutingConfig();
  sideNavStylingConfig: SidenavStylingConfig = new SidenavStylingConfig();

  constructor(private routing: RoutingService) {
    this.styleSideNav();
    this.routing.registerAll();
    this.sidenavRoutingConfig.elements = this.buildSideNavRoutingElements();
  }

  buildSideNavRoutingElements(): SidenavRoutingElement[] {
    const home = new SidenavRoutingElement();
    home.matIconName = 'home';
    home.content = 'Home';
    home.path = '/home';
    const projects = new SidenavRoutingElement();
    projects.matIconName = 'architecture';
    projects.content = 'Projects';
    projects.path = '/projects';
    const contact = new SidenavRoutingElement();
    contact.matIconName = 'mail_lock';
    contact.content = 'Contact';
    contact.path = '/contact';
    return [home, projects, contact];
  }

  styleSideNav() {
    this.sideNavStylingConfig.initialMode = 'auto';
    this.sideNavStylingConfig.vertical.top = '10%';
    this.sideNavStylingConfig.vertical.height = '160px';
    this.sideNavStylingConfig.borderRadiusPx.bottom = 5;
    this.sideNavStylingConfig.highWidthPx = 150;
  }
}
