import { Component, input, InputSignal } from '@angular/core';
import { NgStyle } from '@angular/common';
import {MatCard, MatCardContent} from '@angular/material/card';
import { TextThemeDirective } from 'app/theme/text-theme.directive';

@Component({
  selector: 'app-message-alert',
  standalone: true,
  imports: [
    MatCard,
    TextThemeDirective,
    NgStyle,
    MatCardContent
  ],
  templateUrl: './message-alert.component.html',
  styleUrl: './message-alert.component.scss'
})
export class MessageAlertComponent {
  messageModel = input<AlertMessageModel>(new AlertMessageModel());
}

export class AlertMessageModel {
  show = false;
  content = 'content';
  color = 'black';
  bgColor = 'white';
}
