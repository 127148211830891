
<div [ngStyle]="{width: size(), height: size()}"class="svg">
  <svg
    style="border-radius: 52%;"
    [ngStyle]="{'backgroundColor': theme().getId() === 'dark' ? '#FFF' : 'blue', 'border': border()}"
    id="github"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-143 145 512 512">
    <path [attr.fill]="theme().getId() === 'dark' ? 'blue' : '#DEF'" d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9
		V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7
		C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6
		c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z
		"/>
  </svg>
</div>
