<div class="page" appBgColor *ngIf="isSmallScreen() !== null">
  <div
    class="card-group" [ngClass]="isSmallScreen() ? 'mobile' : 'desktop'">
    <mat-card appTextTheme class="card-one">
      <mat-card-header>
        <mat-card-title>
          Contact me
        </mat-card-title>
      </mat-card-header>
      <mat-card-content style="margin-top: 20px;">
        You can find me on
        <a
          appHyperlink
          target="_blank"
          href="https://www.linkedin.com/in/hesam-eskandari/"
        >LinkedIn</a>
        or send me a message directly
      </mat-card-content>
    </mat-card>
    <mat-card appTextTheme class="card-two">
      <mat-card-header style="margin: 5px auto 10px auto;">
        <mat-card-title style="margin: auto;">
          Compose Email
        </mat-card-title>
      </mat-card-header>
      <div class="center-text">
        <mat-form-field appFormField [topBorderRadius]="'10px'" class="form-field">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Jane Smith"
            [formControl]="name"
            (blur)="updateErrorMessage(name, 'name')"
            required
          />
          @if (name.invalid) {
            <mat-error class="error-message">{{getErrorMessage('name')}}</mat-error>
          }
        </mat-form-field>

        <mat-form-field appFormField class="form-field">
          <mat-label>Email Address</mat-label>
          <input
            matInput
            placeholder="your-email@domain"
            [formControl]="email"
            (blur)="updateErrorMessage(email, 'email')"
            required
          />
          @if (email.invalid) {
            <mat-error>{{getErrorMessage('email')}}</mat-error>
          }
        </mat-form-field>
        <mat-form-field appFormField class="form-field">
          <mat-label class="error-message">Phone Number</mat-label>
          <input
            matInput
            placeholder="1234567890"
            type="phone"
            [maxLength]="14"
            [formControl]="phone"
            (blur)="updateErrorMessage(phone, 'phone')"
          />
          @if (phone.invalid) {
            <mat-error class="error-message">{{getErrorMessage('phone')}}</mat-error>
          }
        </mat-form-field>
        <mat-form-field appFormField [bottomBorderRadius]="'10px'" class="form-field">
          <mat-label>Message</mat-label>
          <textarea
            matInput
            class="message"
            [formControl]="message"
            [maxLength]="220"
            (blur)="updateErrorMessage(message, 'message')"
            required>
          </textarea>
          @if (message.invalid) {
            <mat-error class="error-message">{{getErrorMessage('message')}}</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="button-container">
        <button appSubmitButton
                [isDisabled]="!isEnabled()"
                matTooltipPosition="above"
                [matTooltip]="isEnabled() ? '' : 'Form is not filled properly'"
                (click)="onSendMessage()"
                mat-raised-button
                class="button-send">
          @if (isSending()) {
            <mat-spinner
              [diameter]="30"
              style="--mdc-circular-progress-active-indicator-color: blue;"
              mode="indeterminate"
            ></mat-spinner>
          } @else {
            <div style="display: flex; justify-content: space-between; width: 62px;">
              <mat-icon>send</mat-icon>
              <div>Send</div>
            </div>
          }
        </button>
      </div>
    </mat-card>
  </div>
  <app-message-alert [messageModel]="alertMessage()"></app-message-alert>
</div>
