import { Component, OnDestroy, OnInit, Renderer2, Signal, signal, WritableSignal } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { FormControl, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatCard, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton, MatFabButton } from '@angular/material/button';
import { filter, merge, mergeMap, Observable, Subject, Subscription, tap } from 'rxjs';
import { TextThemeDirective } from 'app/theme/text-theme.directive';
import { Theme, ThemeService } from 'app/theme/theme.service';
import { FormFieldDirective } from 'app/theme/form-field.directive';
import { SubmitButtonDirective } from 'app/theme/submit-button.directive';
import { PortfolioGoService } from 'app/service/portfolio-go/portfolio-go.service';
import { ContactForm } from 'app/service/data-models/contact-form';
import { LinkedinComponent } from 'app/svg/linkedin/linkedin.component';
import { DeviceDetectorService } from 'app/interactor/device-detector/device-detector.service';
import { BackgroundColorDirective } from 'app/theme/background-color.directive';
import { HyperlinkDirective } from 'app/theme/hyperlink.directive';
import { AlertMessageModel, MessageAlertComponent } from 'app/message-alert/message-alert.component';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    BackgroundColorDirective,
    FormFieldDirective,
    LinkedinComponent,
    MatButton,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatError,
    MatFabButton,
    MatFormField,
    MatInput,
    MatLabel,
    MatIcon,
    MatProgressSpinner,
    MatTooltip,
    NgClass,
    NgStyle,
    ReactiveFormsModule,
    SubmitButtonDirective,
    TextThemeDirective,
    HyperlinkDirective,
    MessageAlertComponent,
    NgIf,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements OnInit, OnDestroy {
  readonly sendMessage$$ = new Subject<void>();
  readonly sendMessage$: Observable<void> = this.sendMessage$$.asObservable();
  readonly phone = new FormControl('',
    [Validators.maxLength(14), ContactComponent.minimumLengthNotRequired(10), Validators.pattern('^\\+?[0-9]+$')]);
  readonly message = new FormControl('', [Validators.required, ContactComponent.minimumLength(10)]);
  readonly email = new FormControl('', [Validators.required, Validators.email]);
  readonly name = new FormControl('', [Validators.required, ContactComponent.minimumLength(3)]);
  errorMessages = new Map<string, string>();
  theme: Signal<Theme> = signal(new Theme());
  subscriptions: Subscription[] = [];
  formControlObjs: FormControlObject[] = [];
  isEnabled = signal(false);
  isSending = signal(false);
  isSmallScreen: Signal<boolean | null> = signal(false);
  alertMessage: WritableSignal<AlertMessageModel> = signal(new AlertMessageModel());


  constructor(
    private renderer: Renderer2,
    private themeService: ThemeService,
    private apiService: PortfolioGoService,
    private deviceDetectorService: DeviceDetectorService) {
    this.theme = this.themeService.getTheme();
    this.isSmallScreen = this.deviceDetectorService.isSmallScreen(730);
  }

  private static minimumLength(minLength: number): ValidatorFn {
    return (control) => {
      return control.value?.trim()?.length < minLength ? {'minvalidlength':  true} : null};
  }

  private static minimumLengthNotRequired(minLength: number): ValidatorFn {
    return (control) => {
      return control.value?.trim()?.length < minLength && control.value?.trim()?.length !== 0 ? {'minvalidlength':  true} : null};
  }

  ngOnInit() {
    this.populateFormControlObjects();
    this.subscribeToPostContact();

    this.formControlObjs.forEach(obj => {
        this.subscriptions.push(
          merge(obj.formControl.statusChanges, obj.formControl.valueChanges)
            .subscribe(() => {
              this.updateErrorMessage(obj.formControl, obj.id);
              this.isEnabled.set(this.isFormValid());
            })
        );
      }
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updateErrorMessage(formControl: FormControl, id: string) {
    if (formControl.hasError('required')) {
      this.errorMessages.set(id, `${id} is required`);
    } else if (formControl.hasError('email')) {
      this.errorMessages.set(id, 'Invalid email address');
    } else if (formControl.hasError('pattern')) {
      this.errorMessages.set(id, 'use \'+\' and numbers only');
    } else if (formControl.hasError('minvalidlength')) {
      this.errorMessages.set(id, 'too few letters');
    } else {
      this.errorMessages.set(id, '');
    }
  }

  getErrorMessage(id: string): string {
    return this.errorMessages.get(id) ?? '';
  }

  private isFormValid(): boolean {
    return ![this.message, this.phone, this.email, this.name].some(formControl => formControl.invalid);
  }

  onSendMessage() {
    if (!this.isFormValid()) {
      this.showAlert(false);
      return;
    }
    this.isSending.set(true);
    this.isEnabled.set(false);
    this.sendMessage$$.next();
  }

  private subscribeToPostContact() {
    this.subscriptions.push(
      this.sendMessage$.pipe(
        mergeMap(() => this.apiService.sendContactMessage(new ContactForm(this.name.value!, this.email.value!, this.phone.value!, this.message.value!))),
        filter(() => this.isFormValid()),
        tap(() => this.isEnabled.set(false)),
        tap(() => this.isSending.set(true))
      ).subscribe({
        next: () => {
          this.showAlert(true);
          this.isEnabled.set(true);
          this.isSending.set(false);
          this.email.reset();
          this.name.reset();
          this.phone.reset();
          this.message.reset();
        },
        error: () => {
          this.subscribeToPostContact();
          this.isEnabled.set(true);
          this.isSending.set(false);
          this.showAlert(false);
        }
      })
    );
  }

  private showAlert(isSuccessful: boolean) {
    if (isSuccessful) {
      this.alertMessage.update(message => {
        message.color = this.theme().getPalette('default').getShade('15-80')?.tertiary.toHexString() ?? '#242';
        message.bgColor = this.theme().getPalette('default').getShade('15-40')?.tertiary.toHexString() ?? '#787';
        message.content = 'Message sent';
        message.show = true;
        return message;
      })
    } else {
      this.alertMessage.update(message => {
        message.color = this.theme().getPalette('default').getShade('15-80')?.secondary.toHexString() ?? '#422';
        message.bgColor = this.theme().getPalette('default').getShade('15-40')?.secondary.toHexString() ?? '#877';
        message.content = 'Process failed';
        message.show = true;
        return message;
      })
    }
    setTimeout(() => {
      this.alertMessage.update(message => {
        message.show = false;
        return message;
      });
    }, 3000)
  }

  private populateFormControlObjects() {
    this.formControlObjs.push(new FormControlObject(this.name, 'name'));
    this.formControlObjs.push(new FormControlObject(this.email, 'email'));
    this.formControlObjs.push(new FormControlObject(this.phone, 'phone'));
    this.formControlObjs.push(new FormControlObject(this.message, 'message'));
  }
}

class FormControlObject {
  formControl: FormControl;
  id: string;
  constructor(formControl: FormControl, id: string) {
    this.formControl = formControl;
    this.id = id;
  }
}

