import { Color } from './color';

export type PaletteId = 'custom';
export interface TriadShades {primary: Color, secondary: Color, tertiary: Color}

export class Palette {
  id: PaletteId = 'custom';
  private shades: TriadShades | undefined;

  constructor(id: PaletteId) {
    this.id = id;
  }

  setShades(shades: TriadShades): this {
    this.shades = shades;
    return this;
  }

  getShades(): TriadShades | undefined {
    return this.shades;
  }
}

export class DynamicPallet {
  private palette: Palette;
  private businessShades: TriadShades;

  private shades: Map<string, TriadShades>;

  constructor() {
    this.palette = new Palette('custom');
    this.businessShades = {primary: Color.fromHSL(217, 0, 0), secondary: Color.fromHSL(8, 0, 0), tertiary: Color.fromHSL(76, 0, 0)};
    this.shades = new Map<string, TriadShades>();
  }


  setShadeSL(shadeId: string, saturation: number, lightness: number): this {
    if (saturation < 0) {
      saturation = 0;
    } else if (saturation > 100) {
      saturation = 100;
    }
    if (lightness < 0) {
      lightness = 0;
    } else if (lightness > 100) {
      lightness = 100;
    }
    const triadShades: TriadShades = {
      primary: Color.fromHSL(this.businessShades.primary.getHue(), saturation, lightness),
      secondary: Color.fromHSL(this.businessShades.secondary.getHue(), saturation, lightness),
      tertiary: Color.fromHSL(this.businessShades.tertiary.getHue(), saturation, lightness)
    };
    this.shades.set(shadeId, triadShades);
    return this;
  }

  getShade(shadeId: string): TriadShades | undefined {
    return this.shades.get(shadeId);
  }
}
