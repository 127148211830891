import { computed, Injectable, signal, Signal } from '@angular/core';
import { DeviceDetectorService } from "app/interactor/device-detector/device-detector.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  isMinimized: Signal<boolean | null> = signal(true);
  constructor(private deviceDetectorService: DeviceDetectorService) {

  }

  switchByScreenWidth(width: number) {
    this.isMinimized = this.deviceDetectorService.isSmallScreen(width);
  }

  readUpdates(): Signal<boolean | null> {
    return computed(() => this.isMinimized());
  }
}
