import { Component, input, InputSignal } from '@angular/core';
import { HyperlinkDirective } from "app/theme/hyperlink.directive";

@Component({
  selector: 'app-html-builder',
  standalone: true,
  imports: [
    HyperlinkDirective
  ],
  templateUrl: './html-builder.component.html',
  styleUrl: './html-builder.component.scss'
})
export class HtmlBuilderComponent {
  htmlTextBox: InputSignal<HtmlTextBox> = input(new HtmlTextBox());
}

export class HtmlTextBox {
  text: string | undefined;
  hyperlink: string | undefined;

  setText(text: string | undefined): this {
    this.text = text;
    return this;
  }

  setHyperlink(hyperlink: string | undefined): this {
    this.hyperlink = hyperlink;
    return this;
  }
}
