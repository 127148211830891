import { Component, signal, WritableSignal } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ThemeService } from 'app/theme/theme.service';

@Component({
  selector: 'app-theme-selector',
  standalone: true,
  imports: [
    NgClass,
    MatIcon
  ],
  templateUrl: './theme-selector.component.html',
  styleUrl: './theme-selector.component.scss'
})
export class ThemeSelectorComponent {
  selected = signal('dark');
  themeButtons: WritableSignal<ThemeButton[]> = signal([]);

  constructor(public themeService: ThemeService) {
    this.themeButtons.set(ThemeButton.buildAll())
    this.themeService.updateTheme('dark');
    this.themeButtons.update(arr => arr.map(themeButton => {
      themeButton.isSelected = themeButton.themeId === this.selected();
      return themeButton;
    }))
  }

  onThemeIconClick(value: 'dark' | 'light') {
    this.themeService.updateTheme(value);
    this.selected.set(value);
    this.themeButtons.update(arr => arr.map(themeButton => {
      themeButton.isSelected = themeButton.themeId === value;
      return themeButton;
    }))
  }
}

class ThemeButton {
  themeId: 'dark' | 'light' = 'light';
  isSelected = false;
  iconId: 'dark_mode' | 'light_mode' = 'light_mode';

  static buildAll(): ThemeButton[] {
    const dark = new ThemeButton();
    dark.themeId = 'dark';
    dark.iconId = 'dark_mode';
    const light = new ThemeButton();
    light.themeId = 'light';
    light.iconId = 'light_mode';
    return [light, dark];
  }
}
