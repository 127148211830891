import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { DynamicPallet } from './palette';
import { Color } from "./color";


@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  // #333e4a
  private themes: Theme[] = [
    new Theme('dark')
      .setTextTheme('default', new TextTheme().setFontColor(Color.fromHex('#EEE')).setFontBackgroundColor(Color.fromHex('#303A45')))
      .setPalette('default', this.buildDefaultPalette()),
    new Theme('light')
      .setTextTheme('default', new TextTheme().setFontColor(Color.fromHex('#303A45')).setFontBackgroundColor(Color.fromHex('#EEE')))
      .setPalette('default', this.buildDefaultPalette())
  ];
  private theme: WritableSignal<Theme> = signal<Theme>(new Theme('dark'));

  updateTheme(id: ThemeId) {
    this.theme.update((curr: Theme) => this.themes.find(t => t.getId() === id) ?? curr);
  }

  getTheme(): Signal<Theme> {
    return computed(() => this.theme());
  }

  registerTextTheme(themeId: ThemeId, textThemeId: string, textTheme: TextTheme): void {
    this.themes.filter(theme => theme.getId() === themeId).forEach((theme: Theme) => theme.setTextTheme(textThemeId, textTheme));
  }

  registerDynamicPalette(themeId: ThemeId, paletteId: string, palette: DynamicPallet): void {
    this.themes.filter(theme => theme.getId() === themeId).forEach((theme: Theme) => theme.setPalette(paletteId, palette));
  }

  private buildDefaultPalette(): DynamicPallet {
    const basePallet = new DynamicPallet();
    for (let s = 5; s < 100; s += 5) {
      for (let l = 5; l < 100; l += 5) {
        basePallet.setShadeSL(`${s}-${l}`, s, l);
      }
    }
    return basePallet;
  }
}

export type ThemeId = 'light' | 'dark';

export class TextTheme {
  fontBackgroundColor: Color = Color.fromHSL(0,0,0);
  fontColor: Color = Color.fromHSL(0,0,0);
  fontFamily = 'Roboto sans-serif';

  setFontColor(color: Color): this {
    this.fontColor = color;
    return this;
  }

  setFontFamily(fontFamily: string): this {
    this.fontFamily = fontFamily;
    return this;
  }

  setFontBackgroundColor(color: Color): this {
    this.fontBackgroundColor = color;
    return this;
  }
}

export class Theme {
  private id: ThemeId = 'dark';
  private textThemes: Map<string, TextTheme> = new Map<string, TextTheme>();
  private palettes: Map<string, DynamicPallet> = new Map<string, DynamicPallet>();

  constructor(id: ThemeId = 'dark') {
    this.id = id;
  }

  getTextTheme(id: string): TextTheme {
    return this.textThemes.get(id) ?? this.textThemes.get('default')!;
  }

  setTextTheme(id: string, textTheme: TextTheme): this {
    this.textThemes.set(id, textTheme);
    return this;
  }

  setPalette(id: string, palette: DynamicPallet): this {
    this.palettes.set(id, palette);
    return this;
  }

  getPalette(id: string): DynamicPallet {
    return this.palettes.get(id) ?? this.palettes.get('default')!;
  }

  getId(): ThemeId {
    return this.id;
  }
}
