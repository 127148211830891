@if (messageModel().show) {
  <div >
      <mat-card
        style="width: 300px; padding: 10px; height: 100px; left: 0; top: 0; bottom: 0; position: absolute; margin: calc(50vh - 100px) auto auto calc(50% - 150px)"
        [ngStyle]="{backgroundColor: messageModel().bgColor, color: messageModel().color, opacity: 0.95}">
        <mat-card-content style="width: max-content; margin: auto;">
          {{ messageModel().content }}
        </mat-card-content>
      </mat-card>
  </div>

}
