import { computed, Directive, effect, input, Renderer2, signal, Signal } from '@angular/core';
import { Theme, ThemeService } from './theme.service';
import { Color } from './color';

@Directive({
  selector: '[appBgColor]',
  standalone: true
})
export class BackgroundColorDirective {

  bgColor = input();
  defaultBgColor: Signal<Color | undefined> = signal(undefined);
  private theme: Signal<Theme> = signal(new Theme('dark'));

  constructor(private renderer: Renderer2, private themeService: ThemeService) {
    this.theme = this.themeService.getTheme();
    this.defaultBgColor = computed(() => this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('10-25')?.primary : this.theme().getPalette('default').getShade('15-75')?.primary);
    effect(() => {
      if (typeof document !== 'undefined') {
        this.renderer.setStyle(document.body, 'backgroundColor', this.bgColor() ?? this.defaultBgColor()?.toHexString() ?? 'black');
      }
    });
  }
}
