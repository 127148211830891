export class Color {
  private readonly hue: number;
  private readonly saturation: number;
  private readonly lightness: number;

  private constructor(hue: number, saturation: number, lightness: number) {
    this.hue = hue;
    this.saturation = saturation;
    this.lightness = lightness;
  }

  getHue(): number {
    return this.hue;
  }

  static fromHex(value: string): Color {
    if (!value?.length || value[0] !== '#' || (value.length !== 4 && value.length !== 7)) {
      return new Color(0, 0, 0)
    }
    const mapper: Record<string, number> = {'0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9, a: 10, b: 11, c: 12, d: 13, e: 14, f: 15};
    const redString = value.length === 4 ? value[1].toLowerCase() : value.substring(1, 3).toLowerCase();
    const greenString = value.length === 4 ? value[2].toLowerCase() : value.substring(3, 5).toLowerCase();
    const blueString = value.length === 4 ? value[3].toLowerCase() : value.substring(5, 7).toLowerCase();
    const toRGBNumber = (colorString: string) => colorString.length === 1 ? mapper[colorString[0]] * 16 + mapper[colorString[0]] : mapper[colorString[0]] * 16 + mapper[colorString[1]];
    const red = toRGBNumber(redString);
    const green = toRGBNumber(greenString);
    const blue = toRGBNumber(blueString);
    return Color.fromRGB(red, green, blue);
  }

  static fromRGB(red: number, green: number, blue: number): Color {
    const normalize = (value: number) => value < 0 ? 0 : value > 255 ? 255 : value;
    red = normalize(red);
    green = normalize(green);
    blue = normalize(blue);
    const redPrime = red / 255;
    const greenPrime = green / 255;
    const bluePrime = blue / 255;
    const cMax = Math.max(redPrime, greenPrime, bluePrime);
    const cMin = Math.min(redPrime, greenPrime, bluePrime);
    const delta = cMax - cMin;
    const lightnessNorm = (cMax + cMin) / 2;
    const saturationNorm = delta === 0 ? 0 : delta / (1 - Math.abs(2 * lightnessNorm - 1));
    let hue = 0
    if (delta !== 0) {
      switch (cMax) {
        case redPrime:
          hue = 60 * (((greenPrime - bluePrime) / delta) + 6);
          break;
        case greenPrime:
          hue = 60 * (((bluePrime - redPrime) / delta) + 2);
          break;
        case bluePrime:
          hue = 60 * (((redPrime - greenPrime) / delta) + 4);
          break;
      }
      if (hue < 0) {
        hue = -hue;
      }
    }
    return Color.fromHSL(hue, Math.round(saturationNorm * 100 ), Math.round(lightnessNorm * 100));
  }

  static fromHSL(hue: number, saturation: number, lightness: number): Color {
    let errMessage = '';
    if (!Color.isValidHue(hue)) {
      errMessage = `Invalid hue value ${hue}`;
      hue = Math.max(Math.min(hue, 360), 0);
    }
    if (!Color.isValidSaturation(saturation)) {
      errMessage = `Invalid saturation value ${saturation}`;
      saturation = Math.max(Math.min(saturation, 100), 0);
    }
    if (!Color.isValidLightness(lightness)) {
      errMessage = `Invalid lightness value ${lightness}`;
      lightness = Math.max(Math.min(lightness, 100), 0);
    }
    if (errMessage.length) {
      console.error(new Error(errMessage));
    }
    return new Color(hue, saturation, lightness);
  }

  toString(): string {
    return `${this.hue}-${this.saturation}-${this.lightness}`;
  }

  toHexString(): string {
    const lightness = this.lightness / 100;
    const saturation = this.saturation / 100;
    const a=saturation*Math.min(lightness,1-lightness);
    const f= (n: number,k=(n+this.hue/30)%12) => lightness - a*Math.max(Math.min(k-3,9-k,1),-1);
    return "#" + this.componentToHex(f(0)) + this.componentToHex(f(8)) + this.componentToHex(f(4));
  }

  getOppositeMonochromatic(): Color {
    return Color.fromHSL(this.hue, (this.saturation+50)%100, (this.lightness+50)%100);
  }

  isOtherInSaturationRange(other: Color, range = 30): boolean {
    return this.saturation - other.saturation <= range && this.saturation - other.saturation > -range;
  }

  isOtherInLightnessRange(other: Color, range = 30): boolean {
    return this.lightness - other.lightness <= range && this.lightness - other.lightness > -range;
  }

  isOtherInHueRange(other: Color, range = 30): boolean {
    return this.hue - other.hue <= range && this.hue - other.hue > -range;
  }

  private static isValidHue(hue: number) {
    return hue >= 0 && hue <= 360;
  }

  private static isValidSaturation(saturation: number) {
    return saturation >= 0 && saturation <= 100;
  }

  private static isValidLightness(lightness: number) {
    return lightness >= 0 && lightness <= 100;
  }

  private hslToRgb(h: number, s: number, l: number) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = this.hue2Rgb(p, q, h + 1/3);
      g = this.hue2Rgb(p, q, h);
      b = this.hue2Rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  private hue2Rgb(p: number, q: number, t: number) {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1/6) return p + (q - p) * 6 * t;
    if (t < 1/2) return q;
    if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
    return p;
  }

  private componentToHex(c: number): string {
    const hex = Math.floor(c * 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
}
