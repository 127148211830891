import {computed, Directive, HostBinding, HostListener, signal, Signal, WritableSignal} from '@angular/core';
import {Theme, ThemeService} from "./theme.service";

@Directive({
  selector: '[appHyperlink]',
  standalone: true
})
export class HyperlinkDirective {
  theme: Signal<Theme>;
  hoverColor: WritableSignal<string | null> = signal(null);
  colorKeyDownValue: WritableSignal<string | null> = signal(null);
  color: Signal<string> = signal('white');

  @HostListener('mouseenter', ['$event.target.style']) onMouseEnter() {
    const color = this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('40-60')?.primary.toHexString() : this.theme().getPalette('default').getShade('80-20')?.primary.toHexString();
    this.hoverColor.set(color ?? null);
  }

  @HostListener('mouseleave', ['$event.target.style']) onMouseLeave() {
    this.hoverColor.set(null);
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('keydown.enter', ['$event']) onKeyDown(event: MouseEvent | KeyboardEvent) {
    if (typeof event === typeof MouseEvent && (event as MouseEvent)?.button === 2) {
      return;
    }
    const color = this.theme().getPalette('default').getShade('90-60')?.primary.toHexString();
    this.colorKeyDownValue.set(color ?? null);
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:keyup.enter', ['$event']) onKeyUp(event: MouseEvent | KeyboardEvent) {
    if (typeof event === typeof MouseEvent && (event as MouseEvent)?.button === 2) {
      return;
    }
    this.colorKeyDownValue.set(null);
  }



  @HostBinding('style.color') get fontColor() {
    return this.color();
  }

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.getTheme();
    this.color = computed(() => {
      const regularColor= this.theme().getId() === 'dark' ? this.theme().getPalette('default').getShade('50-80')?.primary?.toHexString() : this.theme().getPalette('default').getShade('50-40')?.primary?.toHexString();
      return this.colorKeyDownValue() ?? this.hoverColor() ?? regularColor ?? 'blue';
    });
  }
}
