import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  static allCookies: string | null = null;

  static populateAllCookies() {
    if (CookieService.allCookies != null) {
      return;
    }
    CookieService.allCookies = document.cookie;
  }

  getCookieFromDocument(cookieName: string): [string, Error | null] {
    CookieService.populateAllCookies();
    return [this.getCookie(cookieName), null];
  }

  private getCookie(name: string) {
    const ca: string[] = document.cookie.split(';');
    const caLen = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }
}
