import { Component, computed, Signal } from '@angular/core';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { ThemeSelectorComponent } from 'app/theme/theme-selector/theme-selector.component';
import { Theme, ThemeService } from 'app/theme/theme.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    MatSelect,
    MatOption,
    FormsModule,
    MatIcon,
    NgClass,
    ThemeSelectorComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  theme: Signal<Theme> | null = null;

  constructor(private themeService: ThemeService) {
    this.theme = computed(() => themeService.getTheme()());
  }
}
