import {Component, input, signal, Signal} from '@angular/core';
import { Theme, ThemeService } from 'app/theme/theme.service';

@Component({
  selector: 'app-puzzle-piece-diag',
  standalone: true,
  imports: [],
  templateUrl: './puzzle-piece-diag.component.html',
  styleUrl: './puzzle-piece-diag.component.scss'
})
export class PuzzlePieceDiagComponent {
  color = input('black');
  theme: Signal<Theme> = signal(new Theme());

  constructor(private themeService: ThemeService) {
    this.theme = this.themeService.getTheme();
  }
}
