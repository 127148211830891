import { Component, computed, OnInit, signal, Signal, WritableSignal } from '@angular/core';
import { NgClass, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { MatList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardImage } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { SampleOneComponent } from 'app/background/sample-one/sample-one.component';
import { DeviceDetectorService } from 'app/interactor/device-detector/device-detector.service';
import { Theme, ThemeService } from 'app/theme/theme.service';
import { TextThemeDirective } from 'app/theme/text-theme.directive';
import { GithubComponent } from 'app/svg/github/github.component';
import { LinkedinComponent } from 'app/svg/linkedin/linkedin.component';
import { PuzzlePieceDiagComponent } from 'app/svg/puzzle-piece-diag/puzzle-piece-diag.component';
import { BackgroundColorDirective } from 'app/theme/background-color.directive';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    SampleOneComponent,
    MatCard,
    NgClass,
    MatList,
    MatListItem,
    MatIcon,
    MatCardImage,
    NgOptimizedImage,
    NgStyle,
    TextThemeDirective,
    GithubComponent,
    LinkedinComponent,
    PuzzlePieceDiagComponent,
    PuzzlePieceDiagComponent,
    BackgroundColorDirective,
    NgIf
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit{
  isSmallScreen: Signal<boolean | null> = signal(null);
  isImageLoaded = signal(false);
  theme: Signal<Theme> = signal(new Theme());
  highlights: WritableSignal<string[]> = signal([]);
  matIconColor: Signal<string> = signal('black');

  constructor(
    private deviceDetector: DeviceDetectorService,
    private themeService: ThemeService)
  {
    this.theme = this.themeService.getTheme();
    this.matIconColor = computed(() => this.theme().getPalette('default').getShade(this.theme().getId() === 'dark' ? '50-65' : '70-45')?.primary?.toHexString() ?? this.matIconColor());
    this.isSmallScreen = this.deviceDetector.isSmallScreen(1000);
  }

  ngOnInit() {
    this.highlights.set([
      'Four years of experience in full-stack software development',
      'Cloud Technologies: MS Azure, Digital Ocean, GCP, and Linode',
      'Automation: CICD, dockerized service, and orchestration',
      'Experience in agile environments'
    ])
  }

  onImageLoad() {
    this.isImageLoaded.set(true);
  }

  onLinkedInClicked() {
    window?.open('https://www.linkedin.com/in/hesam-eskandari/');
  }

  onGithubClicked() {
    window?.open('https://github.com/Hesam-Eskandari');
  }
}
